import request from "@/services/request";
import { removeToken, removeMvpToken } from "@/utils/auth";

export function login(data) {
  return request({
    url: "/login",
    method: "post",
    data,
  });
}
export function forgetPwd(data) {
  return request({
    url: "/system/user/updatePassEmail",
    method: "post",
    data,
  });
}
//获取用户信息
export function getNotificationList(page = 1) {
  return request({
    url: "/system/user/notifications?pageSize=3&pageNum=" + page
    // url: "/system/user/notifications"
  });
}
export function getUserInfo() {
  return request({
    url: "/getInfo",
  });
}
export const checkCode = (code) => {
  return request({
    url: "/authCode",
    params: {
      code,
    },
    method: "get",
  });
};
export function goActivate(code) {
  return request({
    url: "/verifyAccount/" + code,
    method: "get",
  });
}
// 首次验证账号
export function checkAccount(uid) {
  return request({
    url: `/system/user/verification/${uid}`,
    method: "get",
  });
}
export const verifyAccount = (data) => {
  return request({
    url: "/system/user/verifyAccount",
    method: "post",
    data,
  });
};
export const LogOut = (data) => {
  return request({
    url: "/",
    method: "post",
    data,
  });
};
export const mvpLogin = (data) => {
  return request({
    url: "/mvpLogin",
    data,
    method: "post",
  });
};
export const loginByCode = (code)=>{
  return request({
      url:'/codeLogin',
      method:'get',
      params:{
          code,
      },
  })
}
export const loginByToken = (accessToken)=>{
  return request({
      url:'/tokenLogin',
      method:'get',
      params:{
        accessToken,
      },
  })
}
export const getMvpShips = (mvpCompanyId) => {
  return request({
    url: "/getMvpShips?mvpCompanyId=" + mvpCompanyId,
    method: "get"
  })
}
export const getMvpCompany = ({ imo, timestamp, cancelToken }) => {
  return request({
    url: "/getMvpCompany?imo=" + imo + "&timestamp=" + timestamp,
    method: "get",
    cancelToken,
  })
}
export const BargeAdd = (data) => {
  // 驳船新增
  return request({
    url: "/jput/nomination",
    method: "post",
    data,
  });
};
export const SendEmail = (data) => {
  // 驳船新增
  return request({
    url: "/jput/nomination/sendEmail",
    method: "post",
    data,
  });
};
export const mapCheckCode = (params) => {
  return request({
    url: "/mvpAuthCode",
    method: "get",
    params,
  });
};
export const getPasswordAuthenticatorStatus = ({ username }) => {
  return request({
    url: "/getPasswordAuthenticatorStatus?username=" + username,
    method: "get"
  });
};
// barge login
export const bargeLogin = (data) => {
  return request({
    url: "/bargeLogin",
    data,
    method: "post",
  });
};
// barge auth code
export const bargeAuthCode = (params) => {
  return request({
    url: "/bargeAuthCode",
    params,
    method: "get",
  });
};
export function getSelectVessel() {
  // 下拉数据
  return request({
    url: "/jput/vessel/selectVessel",
  });
}
export function searchBarge(pattern) {
  // 下拉数据
  return request({
    url: `/jput/vessel/search?pattern=${pattern}`,
    method: "get",
  });
}
export function getClientList(
  status,
  pageParams = {},
  searchValue,
  searchDateType,
  startDate,
  endDate,
  cancelToken,
  statusString
) {
  // 驳船列表
  return request({
    url: "/jput/nomination/list/",
    params: {
      status,
      ...pageParams,
      searchValue,
      searchDateType,
      startDate,
      endDate,
      statusString,
    },
    method: "get",
    cancelToken
  });
}

// form表单修改
export const submitFreshWaterAvailable = (data) => {
  return request({
    url: "/jput/nomination",
    data,
    method: "PUT"
  });
};

export const Amendnomination = (data, cancelToken) => {
  return request({
    url: "/jput/nomination",
    data,
    method: "PUT",
    cancelToken
  });
};

export const Booknomination = ( id ) => {
  return request({
    url: "/jput/nomination/book/" + id,
    method: "PUT"
  });
};

export function getnominationLogList(BId, pageNum, pageSize) {
  // 驳船日志埋点
  pageNum = pageNum ?? 1
  pageSize = pageSize ?? 20
  return request({
    url: "/jput/nominationLog/list",
    params: {
      BId,
      pageNum,
      pageSize
    },
    method: "get",
  });
}

// 表单Tank下拉数据
export const formTankselect = () => {
  return request({
    url: "/jput/tank/getList",
    method: "get",
  });
};

// 表单Tank下拉数据
export const formSurveyorselect = (role, userType) => {
  return request({
    url: "/system/user/getUserList",
    params: {
      role,
      userType,
    },
    method: "get",
  });
};

// 表单详情接口
export const formall = (id) => {
  return request({
    url: "/jput/nomination/" + id,
    method: "get",
  });
};

export const previewPdf = (id) => {
  return request({
    url: "/jput/nomination/" + id + "/previewPdf",
    method: "get",
  });
};

// 码头Jetty详情接口
export const JettyAll = () => {
  return request({
    url: "/jput/berth/list",
    method: "get",
    params:{
      pageSize:12
    }
  });
};

export const cancelbarging = (data) => {
  // 取消form表单按钮
  return request({
    url: "/jput/nomination/revocationNomination",
    method: "post",
    data,
  });
};

// 创建并提交修改请求
export const modificationform = (data) => {
  return request({
    url: "/jput/nomination/requestForModification",
    method: "post",
    data,
  });
};

export const updateNominatedQtyMax = (data) => {
  return request({
    url: "/jput/nomination/requestNominatedQtyMaxUpdate",
    method: "post",
    data,
  });
};

// 用户管理列表 company
export function getcompanyList(userType) {
  return request({
    url: "/system/user/list",
    params: {
      userType,
    },
    method: "get",
  });
}

// requests 列表
export function getrequestsList(applyType = 0, pageNum = 1, pageSize = 10) {
  const showAllRequest = true
  const params = applyType == 3 ? { showAllRequest, pageNum, pageSize } : { applyType, pageNum, pageSize }
  return request({
    url: "/jput/request/list",
    method: "get",
    params: params,
  });
}

// 重置签名
export function getResers(id) {
  return request({
    url: "/jput/request/resetSign?id=" + id,
    method: "get",
  });
}

// 客户申请修改
export const AcknowData = (data) => {
  return request({
    url: "/jput/request",
    data,
    method: "PUT",
  });
};

// 用户管理列表 Customer 2 和 Surveyor 3
export function getCSSList(userType, searchValue) {
  return request({
    url: "/system/user/getList",
    method: "get",
    params: {
      userType,
      searchValue,
    },
  });
}
// 公司查询本公司的用户列表
export function getUserList() {
  return request({
    url: "/system/user/getUserList",
    method: "get",
  });
}
// 新增用户 or 新增公司
export function addUser(data) {
  return request({
    url: "/system/user",
    method: "post",
    data,
  });
}
// 编辑用户 or 编辑公司
export function editUser(data) {
  return request({
    url: "/system/user",
    method: "put",
    data,
  });
}
// 冻结用户
export function freezeUser(data) {
  return request({
    url: "/system/user/freeze",
    method: "put",
    data,
  });
}
// 解冻用户
export function unFreezeUser(data) {
  return request({
    url: "/system/user/unfreeze",
    method: "put",
    data,
  });
}
// 修改密码
export function updatePassword(data) {
  return request({
    url: "/system/user/updatePass",
    method: "post",
    data,
  });
}

// 查询company 数据条数
export function getcompanyLists() {
  return request({
    url: "/system/user/getUserNumber",
    method: "get",
  });
}
// Resend Request
export function revalidateEmail(id) {
  return request({
    url: "/system/user/revalidateEmail",
    method: "get",
    params: { id },
  });
}
// 退出方法
export function logout() {
  removeToken();
  removeMvpToken();
  return request({
    url: "/logout",
    method: "post",
  });
}
export function getQuantityNum() {
  return request({
    url: "/jput/quantity/getQuantityNum",
  });
}
export function getBillOfLadingNum() {
  return request({
    url: "/jput/billOfLading/getBillOfLadingNum",
  });
}
// 删除用户
export function deleteUser(userIds){
  return request({
    url:`/system/user/${userIds}`,
    method:"delete"
  })
}
// get version
export function getVersion(){
  return request({
    url: "/jput/version",
    method:'get',
  })
}

export function delUllageReport(nId, ullageId){
  return request({
    url: "/jput/form/delUllageReport",
    method:'post',
    data: { nId, ullageId },
  })
}

export function searchProduct(type, searchString){
  // type could be ALL, Port, Starboard and Center
  let name = searchString ?? ''
  type = type ?? 'ALL'
  return request({
    url: "/jput/product/searchProduct",
    method:'post',
    data: { name, type },
  })
}

export function addProduct(type, name){
  // type could be Port, Starboard and Center
  return request({
    url: "/jput/product/addProduct",
    method:'post',
    data: { name, type },
  })
}

export function delProduct(pId){
  return request({
    url: "/jput/product/delProduct?pId=" + pId,
    method:'post',
  })
}

// check mvp authentication
export function checkMvpAuthentication( code ){
  return request({
    url: "/jput/version",
    method:'post',
  })
}