<template>
  <div class="layout" id="main-layout" >
    <div class="head w-full flex justify-between items-center pr-6 mobile:pl-0 mobile:pr-3"    
        :class="{'pl-6':!isMobileOrTablet}"      
    >
      <!--  placement="right-start" -->
      <el-popover
        v-if="isMobileOrTablet"
        width="280"
        trigger="click"
        ref="popover"
        >
        <el-menu
          :collapse="false"
          active-text-color="#008CDB"
          :default-active="$route.path"
          :router="true"
          class="mobile-menu">
          <template v-for="item in $store.state.routes">
            <el-submenu
              v-if="item.children?.length > 1 && item.component"
              :key="item.path"
              :index="item.path"
            >
              <template slot="title">
                <p>
                  <svg-icon
                    v-if="item.meta.icon"
                    :icon-class="item.meta.icon"
                  ></svg-icon>

                  <span class="pr-5">{{ $t(item.meta.key) }}</span>
                </p>
              </template>
              <el-menu-item
                v-for="it in item.children"
                :key="item.path + '/' + it.path"
                :index="item.path + '/' + it.path"
              >
                <svg-icon
                  v-if="it.meta.icon"
                  :icon-class="it.meta.icon"
                ></svg-icon>

                <span>{{ $t(it.meta.key) }}</span>
              </el-menu-item>
            </el-submenu>
            <el-menu-item
              v-if="item.children?.length <= 1 && item.component"
              :key="item.path + '/' + item.children[0].path"
              :index="item.path + '/' + item.children[0].path"
            >
              <svg-icon
                v-if="item.children[0].meta"
                :icon-class="item.children[0].meta.icon"
              ></svg-icon>
              <template slot="title">
                <span class="pr-5">{{ $t(item.children[0].meta.key) }}</span>
              </template>
            </el-menu-item>
          </template>
        </el-menu>
        <el-button slot="reference">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" color="#606266">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </el-button>
      </el-popover>
      <div v-else @click="isCollapse = !isCollapse" class="cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" color="#606266">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </div>
      <div class="flex items-center cursor-pointer">
        <div class="app-version">{{ appVersion }}</div>
        <el-button type="text" title="User Guide">
          <a href="../User Guide.pdf" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-6 h-6" color="#606266">
              <circle cx="12" cy="12" r="10" stroke="#606266" stroke-width="1.5"/>
              <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#606266" stroke-width="1.5" stroke-linecap="round"/>
              <circle cx="12" cy="16" r="1" fill="#606266"/>
            </svg>
          </a>
        </el-button>
        <el-button v-if="!isAdmin" type="text" @click="expandNotificationDrawer()" title="Notifications">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" color="#606266">
            <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
          </svg>
        </el-button>
        <el-dropdown class="pl-2" trigger="click" @visible-change="handleDropdownButtonClick">
          <span class="el-dropdown-link">
            <div class="flex items-center select-none">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <span v-if="userInfo.userType == 4" class="pl-2"
                >{{ userInfo.userName }} ({{ userInfo.nickName }})</span
              >
              <span v-else class="pl-2">{{ userInfo.nickName }} </span>
              <div class="pl-1">
                <svg v-if="showDropdownMenu" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
              </div>
            </div>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="!isBarge" @click.native="showChangePasswordDialog = true"
              >Change Password</el-dropdown-item
            >
            <el-dropdown-item @click.native="handleViewProfileClick"
              >View Profile</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <el-select
          :value="locale"
          style="width: 100px; margin-right: 15px"
          @change="changeLang"
        >
          <el-option label="English" value="en"></el-option>
          <el-option label="简体中文" value="zh"></el-option>
        </el-select> -->
        <div class="pl-2">
          <el-button type="text" @click.native="handleSignOut" title="Log Out">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" color="#606266">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
            </svg>
          </el-button>
        </div>
      </div>
    </div>
    <div class="main flex w-full">
      <el-menu 
        v-if="!isMobileOrTablet"
        :collapse="isCollapse"
        active-text-color="#008CDB"
        :default-active="$route.path"
        :router="true"
        class="menu-scroll"
        :style="menuStyles"
      >
        <template v-for="(item, index) in $store.state.routes">
          <!-- Submenu with multiple children -->
          <el-submenu
            v-if="item.children?.length > 1 && item.component && !['PSA', 'COA Link', 'Port Clear'].includes($t(item.meta.key))"
            :key="item.path"
            :index="item.path"
          >
            <template slot="title">
              <p>
                <svg-icon v-if="item.meta.icon" :icon-class="item.meta.icon" />
                <span class="pr-5">{{ $t(item.meta.key) }}</span>
              </p>
            </template>
            <!-- Loop over children -->
            <el-menu-item
              v-for="(it, i) in item.children"
              v-if="!['PSA', 'COA Link', 'Port Clear'].includes($t(it.meta.key))"
              :key="item.path + '/' + it.path"
              :index="item.path + '/' + it.path"
            >
              <svg-icon v-if="it.meta?.icon" :icon-class="it.meta.icon" />
              <span>{{ $t(it.meta.key) }}</span>
            </el-menu-item>
          </el-submenu>

          <!-- Single-item route -->
          <el-menu-item
            v-else-if="item.children?.length === 1 && item.component && item.children[0] && !['PSA', 'COA Link', 'Port Clear'].includes($t(item.children[0].meta.key))"
            :key="item.path + '/' + item.children[0].path"
            :index="item.path + '/' + item.children[0].path"
          >
            <svg-icon v-if="item.children[0].meta?.icon" :icon-class="item.children[0].meta.icon" />
            <template slot="title">
              <span class="pr-5">{{ $t(item.children[0].meta.key) }}</span>
            </template>
          </el-menu-item>
        </template>
        <el-image v-if="! isCollapse" :src="leftPanel" class="bottomImage"></el-image>
      </el-menu>
      <div class="page w-full" :style="{backgroundImage: 'url('+rightPanel+')' }">
        <div class="rightImage">
          <el-image :src="rightPanel"></el-image>
        </div>
        <div
          class="overflow-auto p-0 px-1.5 laptop:p-2 desktop:py-6 desktop:px-8"
          :style="{
            width: isMobileOrTablet? '100vw' : '',
            padding: '20px',
            position: 'relative',
            height: 'calc(100vh - 50px)',
          }"
          @scroll="onScroll"
        >
          <div id="backToTop">
          <transition name="fade-transform" mode="out-in">
            <router-view></router-view>
          </transition>
            <div v-if="topButtonShow" class="goToTop" >
              <el-button plain size="small" type="primary" @click="goToTop">
                Top
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      title="Notification"
      :visible.sync="notificationDrawer"
      direction="rtl"
      size="20%"
      :modal="false"
      @open="initializeNotifcationDrawer()"
      @close="dismissNotifcationDrawer()">
      <h1 slot="title" style="font-size: 20px;">Notifications</h1>
      <div 
        v-infinite-scroll="loadMore" 
        infinite-scroll-disabled="loading"     
        infinite-scroll-distance="10"
        style="height: 100%;overflow: auto;">
        <ul style="padding: 0 20px 20px 20px;">
          <div
            class="pb-4"
            v-for="notification in notifications"
            :key="'notification-' + notification.id">
            <li class="flex flex-col">
              <div class="flex flex-start items-start gap-2 mb-1">
                <p style="font-size: 14px;" v-html="notification.action"></p>
                <el-button style="padding-top: 2px" type="text" v-if="notification.bid" @click="handleOpenBarge(notification.bid,notification.action)">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4" color="#606266">
                    <path fill-rule="evenodd" d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z" clip-rule="evenodd" />
                    <path fill-rule="evenodd" d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z" clip-rule="evenodd" />
                  </svg>
                </el-button>
              </div>
              <small style="font-size: 11px;">{{ notification.createTime }}</small>
              <small style="font-size: 9px;">Reference ID: {{ notification.id }}</small>
            </li>
          </div>
        </ul>
      </div>
    </el-drawer>
    <el-dialog
      :width="isMobileOrTablet?'90%':'450px'"
      title="My Profile"
      :center="true"
      :visible.sync="showViewProfileDiglog"
      append-to-body
    >
      <div>
        <p class="flex items-center">
          <span
            :style="{
              'text-align': !isCustomerAdminOrSurveyorAdmin ? 'right' : 'left',
            }"
            >Name:</span
          >
          <span
            style="padding-left: 5px"
            v-if="!isCustomerAdminOrSurveyorAdmin"
            class="font-bold"
            >{{ userInfo.nickName }}</span
          >
          <el-input
            style="width: 250px; padding-left: 5px"
            v-else
            size="small"
            v-model="editData.userName"
          />
        </p>
        <p class="flex items-center">
          <span
            :style="{
              width: !isCustomerAdminOrSurveyorAdmin ? 'auto' : '70px',
              'text-align': isCustomerAdminOrSurveyorAdmin ? 'right' : 'left',
            }"
            >Email:</span
          >
          <span
            style="padding-left: 5px"
            v-if="!isCustomerAdminOrSurveyorAdmin"
            class="font-bold"
            >{{ userInfo.email }}</span
          >
          <el-input
            style="width: 250px; padding-left: 5px; padding-top: 10px"
            v-else
            size="small"
            v-model="editData.email"
          />
        </p>
        <p class="flex items-center">
          <span
            :style="{
              width: !isCustomerAdminOrSurveyorAdmin ? 'auto' : '70px',
              'text-align': isCustomerAdminOrSurveyorAdmin ? 'right' : 'left',
            }"
            >Role:</span
          >
          <span class="font-bold" style="padding-left: 5px">{{
            ROLE_MAP[userInfo.role]
          }}</span>
        </p>
        <p class="flex items-center" v-if="userInfo.companyName">
          <span>companyName:</span>
          <span class="font-bold" style="padding-left: 5px">{{
            userInfo.companyName
          }}</span>
        </p>
        <div
          v-if="isCustomerAdminOrSurveyorAdmin"
          class="flex justify-center mt-2"
        >
          <el-button
            @click="handleUpdateUserInfoClick"
            size="mini"
            type="primary"
            >Update Profile</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog
       :width="isMobileOrTablet?'90%':'450px'"
      title="Change Password"
      :center="true"
      :visible.sync="showChangePasswordDialog"
      append-to-body
      @close="handleClearChangePasswordForm"
    >
      <el-form
        ref="form"
        size="small"
        label-position="top"
        :model="changePasswordForm"
        class="changePassword"
      >
        <el-form-item label="Current Password:">
          <el-input
            size="small"
            v-model="changePasswordForm.oldPassword"
            type="password"
            clearable
            :show-password="false"
          ></el-input>
        </el-form-item>
        <el-form-item label="New Password:">
          <el-input
            size="small"
            type="password"
            clearable
            :show-password="false"
            v-model="changePasswordForm.password"
          ></el-input>
        </el-form-item>
        <el-form-item label="Confirm Password:">
          <el-input
            size="small"
            type="password"
            clearable
            :show-password="false"
            v-model="changePasswordForm.confirmNewPassword"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="flex justify-center">
        <el-button size="small" @click="handleClearChangePasswordForm"
          >Cancel</el-button
        >
        <el-button
          size="small"
          type="primary"
          :loading="confirmBtnLoading"
          @click="handleConfirmUpdatePasswordClick"
          >Confirm</el-button
        >
      </div>
      <!-- <div class="rules">
        <p>New Password Requirements:</p>
        <p v-for="(tip, index) in passwordRules" :key="index">{{ tip }}</p>
      </div> -->
    </el-dialog>
    <LetterToBargeOwner/>
  </div>
</template>

<script>
import clockImg from "@/assets/icon/clock.png";
import signOutImg from "@/assets/icon/signOut.png";
import leftPanel from "@/assets/leftPanel.jpg";
import rightPanel from "@/assets/rightPanel.jpg";
import { mapState } from "vuex";
import { getToken, getMvpToken, removeToken, removeMvpToken } from "@/utils/auth";
import SvgIcon from "@/components/SvgIcon.vue";
import { editUser, logout, updatePassword, getVersion } from "@/services/user";
import{ ROLE_MAP } from'@/utils/constants.js';
import {Howl} from 'howler';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import LetterToBargeOwner from "@/components/common/LetterToBargeOwner";
// const rolesMap = {
//   0: "Admin",
//   1: "Terminal Logistics",
//   2: "Terminal Ops",
//   3: "Terminal Team Leader",
//   4: "Company Admin",
//   5: "Customer User",
//   6: "Surveyor Admin",
//   7: "Surveyor",
//   8: "Barge",
//   9: "Barge Operator"
// };
export default {
  components: { SvgIcon, LetterToBargeOwner },
  name: "index",
  data() {
    return {
      ROLE_MAP,
      clockImg,
      signOutImg,
      leftPanel,
      rightPanel,
      notificationDrawer: false,
      isCollapse: false,
      isMobileCollapse: true,
      showDropdownMenu: false,
      showViewProfileDiglog: false,
      showChangePasswordDialog: false,
      changePasswordForm: {
        oldPassword: "",
        password: "",
        confirmNewPassword: "",
      },
      confirmBtnLoading: false,
      editData: {
        userName: "",
        email: "",
      },
      updateProfileLoading: true,
      page: 1,
      sseClient: null,
      isMobileOrTablet:false,
      isMobile:false,
      topButtonShow: false,
      passwordRules: [
        "i.   At least 1 Upper case (A through Z)",
        "ii.  At least 1 Lower case (a through z)",
        "iii. At least 1 Digit (0-9)",
        "iv.  At least 1 Special Character (!, $, #, %, @)",
        "v.   Length must be between 12 and 2000 characters",
      ],
      appVersion:"",
      loading:false,
    };
  },
  watch:{
    '$route.path'(newPath, oldPath) {
      if (this.isMobileOrTablet) {
        this.$refs.popover.doClose();
      }
    },
  },
  computed: {
    ...mapState(["locale"]),
    ...mapState(["userInfo"]),
    ...mapState(["notifications"]),
    ...mapState(["total"]),
    isCustomerAdminOrSurveyorAdmin() {
      return false;
    },
    isAdmin() {
      return this.userInfo?.userType == 0;
    },
    isBarge() {
      return this.userInfo?.userType == 4;
    },
    menuStyles() {
      return {
        maxWidth: this.isCollapse ? '225px' : 'none',
        minWidth: this.isCollapse ? 'none' : '230px',
      };
    },
  },
  created() {
    getVersion().then(res => {
      if (res) {
        this.appVersion = "Version " + res.msg
      }  
    })
    
    if (this.isCustomerAdminOrSurveyorAdmin) {
      this.editData.userName = this.userInfo.userName;
      this.editData.email = this.userInfo.email;
    }
    
    // this.$store.dispatch("getNotificationList", this.page)
  },
  mounted() {
    const headers = {
      Authorization: `Bearer ${getToken()}`
    };

    if (!this.isAdmin) {
      this.sseClient = this.$sse.create({
        url: process.env.VUE_APP_BASE_API + '/system/user/notifications/sse',
        polyfill: true,
        forcePolyfill: true,
        polyfillOptions: { headers }
      })

      this.sseClient.on('message', (msg) => {
        const payloadJSON = JSON.parse(msg)
        if (payloadJSON.bid && !payloadJSON.action.includes("Ship Vetting")) {
          this.playSound()
          this.$notify.success({
            position: "bottom-right",
            title: "Barging Nomination",
            duration: 10000,
            dangerouslyUseHTMLString: true,
            message: payloadJSON.action,
            onClick: () => {
              if (payloadJSON.bid) {
                this.$router.push({
                  path: "/barging/index",
                  query: {
                    id: payloadJSON.bid,
                    pageStatus: 1,
                  },
                });
              }
            }
          })
          this.restartNotifications()
        }
        if(payloadJSON.bid && payloadJSON.action.includes("Ship Vetting")){
          this.playSound()
          this.$notify.success({
            position: "bottom-right",
            title: "Ship Vetting",
            duration: 10000,
            dangerouslyUseHTMLString: true,
            message: payloadJSON.action,
            onClick: () => {
              if (payloadJSON.bid) {
                this.$router.push({
                  path: "/ship-vetting/index",
                  query: {
                    id: payloadJSON.bid,
                    pageStatus: 1,
                  },
                });
              }
            }
          })
          this.restartNotifications()
        }
        if (payloadJSON.action == "test") {
          this.playSound()
          this.$notify.success({
            position: "bottom-right",
            title: "Test",
            duration: 10000,
            dangerouslyUseHTMLString: true,
            message: payloadJSON.action
          })
        }
        if (payloadJSON.action == "connected") {
          // console.log("Connected to SSE")
        }
      })

      this.sseClient.on('error', (err) => {
        console.error('lost connection or failed to parse!', err);
        // If this error is due to an unexpected disconnection, EventSource will
        // automatically attempt to reconnect indefinitely. You will _not_ need to
        // re-add your handlers.
      })
      
      this.sseClient
        .connect()
        .then(sse => {
          // console.log('We\'re connected!');
        })
        .catch((err) => {
          // When this error is caught, it means the initial connection to the
          // events server failed.  No automatic attempts to reconnect will be made.
          console.error('Failed to connect to server', err);
        })
    }
    this.handleLayoutReSize()
    window.onresize=()=>{
      this.handleLayoutReSize()
    }

     this.handleLayoutReSize()
    window.onresize=()=>{
      this.handleLayoutReSize()
    }
  },
  beforeDestroy() {
    if (!this.isAdmin) {
      // Make sure to close the connection with the events server
      // when the component is destroyed, or we'll have ghost connections!

      if (this.sseClient) {
        this.sseClient.disconnect();
      }

      // Alternatively, we could have added the `sse: { cleanup: true }` option to our component,
      // and the SSEManager would have automatically disconnected during beforeDestroy.
    }
  },
  methods: {
    onScroll(event) {
      this.topButtonShow = event.target.scrollTop > 300
    },
    goToTop() {  
      smoothScrollIntoView(
        document.getElementById('backToTop'), { 
          behavior: 'smooth', 
          block: 'start', 
          inline: 'start', 
          scrollMode: 'if-needed' 
        }
      )
    },
    handleLayoutReSize(){
      const div = document.getElementById("main-layout");
      const w = div.offsetWidth;  //返回layout dom的总宽度
      this.isMobileOrTablet = w<=1024;
      this.isMobile = w<=589;
      this.isCollapse = !(w>=1441);
      const payload = {
        isMobileScreen: this.isMobile,
        isMobileOrTabletScreen:this.isMobileOrTablet,
        isLaptop: w>1024,
        mainLayoutWidth:w,
      }
      this.$store.dispatch('setScreen',payload);
    },
    playSound() {
      var sound = new Howl({
        src: ['/notification-sound-7062.mp3']
      });
      sound.play();
    },
    changeLang() {
      this.$i18n.locale = this.$i18n.locale === "zh" ? "en" : "zh";
      this.$store.commit("changeLocale", this.$i18n.locale);
    },
    handleDropdownButtonClick() {
      this.showDropdownMenu = !this.showDropdownMenu;
    },
    handleSignOut() {
      let mvpToken = getMvpToken()
      logout().then(() => {
        removeToken();
        removeMvpToken();
        let isMvp = !( mvpToken == '' || mvpToken == null )
      //  location.href = mvpToken == '' || mvpToken == null ? "/login" : "/mvpLogin";
        location.href = "/login" + ( isMvp ? '?isMvp=true' : '' )
        sessionStorage.removeItem("pageStatus");
        sessionStorage.removeItem("editData");
      });
    },
    handleUpdateUserInfoClick() {
      editUser({
        ...this.editData,
        userId: this.userInfo.userId,
      }).then(() => {
        this.$message.success(this.$t("status.success"));
      });
    },
    handleViewProfileClick() {
      this.showViewProfileDiglog = true;
    },
    handleConfirmUpdatePasswordClick() {
      this.$refs.form.validate((res) => {
        if (res) {
          this.$message.closeAll();
          const passwordNew = this.changePasswordForm.password
          const passwordNewConfirm = this.changePasswordForm.confirmNewPassword
          // const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@$%^&*+#]).{12,2000}$/;
          const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@$%^&*+#])[A-Za-z\d!@$%^&*+# ]{12,2000}$/;
          if (!regex.test(passwordNew)) {
            this.$message({
              message: "New " + this.$t("tips.pwNotValid"),
              type: "error",
              duration: 0,
              showClose: true,
            });
            return;
          }
          if (passwordNew !== "" && passwordNew != passwordNewConfirm) {
            this.$message({
              message: "New " + this.$t("tips.pwNotMatch"),
              type: "error",
              duration: 0,
              showClose: true,
            });
            return;
          }
          
          // if (
          //   this.changePasswordForm.password !==
          //   this.changePasswordForm.confirmNewPassword
          // ) {
          //   return this.$message.error("Passwords do not match");
          // }
          this.confirmBtnLoading = true;
          updatePassword({
            oldPassword: this.changePasswordForm.oldPassword,
            password: this.changePasswordForm.password,
          })
            .then(() => {
              this.$message.success(this.$t("status.success"));
              removeToken();
              setTimeout(() => {
                location.href = "/login";
              }, 300);
            })
            .finally(() => {
              this.confirmBtnLoading = false;
            });
        }
      });
    },
    handleClearChangePasswordForm(){
      this.changePasswordForm = {
        oldPassword: "",
        password: "",
        confirmNewPassword: "",
      };
      this.showChangePasswordDialog = false
      this.$message.closeAll();
    },
    restartNotifications() {
      // console.log("restart " + this.page)
      this.page = 1;
      this.$store.dispatch("clearNotificationList")
      this.$store.dispatch("getNotificationList", this.page)
    },
    // restartNotifications() {
    //   return new Promise(async (resolve) => {
    //     this.page = 1;
    //     await this.$store.dispatch("clearNotificationList");
    //     await this.$store.dispatch("getNotificationList", this.page);
    //     resolve();
    //   });
    // },
    async expandNotificationDrawer(){
      // console.log("expand " + this.page)
      this.notificationDrawer = true;
      this.restartNotifications();
      await this.sleep(1000);
      this.loadMore();
    },
    // async expandNotificationDrawer() {
    //   this.notificationDrawer = true;
    //   await this.restartNotifications();
    //   this.loadMore();
    // },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    initializeNotifcationDrawer(){
      // console.log("ini " + this.page)
      // this.restartNotifications()
      // console.log();
      // this.loadMore();
    },
    dismissNotifcationDrawer(){
      // console.log("dismiss " + this.page)
      this.$store.dispatch("clearNotificationList")
    },
    loadMore() {
      // console.log("load " + this.page)
      // console.log( this.total + " noti " + this.notifications.length)
      if (this.total <= this.notifications.length){
        // console.log("stop")
        return;
      }
      this.loading = true
      this.page += 1;
      this.$store.dispatch("getNotificationList", this.page)
      this.loading = false
    },
    handleOpenBarge(bid,action) {
      if(!action.includes("Ship Vetting")){
        this.$router.push({
          path: "/barging/index",
          query: {
            id: bid,
            pageStatus: 1,
          },
        });
      }else{
        this.$router.push({
          path: "/ship-vetting/index",
          query: {
            id: bid,
            pageStatus: 1,
          },
        });
      }
      
      this.notificationDrawer = false;
    }
  },
};
</script>

<style scoped lang="scss">
.layout {
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  background: transparent;

  .head {
    position: absolute;
    // width: 100%;
    height: 50px;
    // padding-left: 24px;
    // padding-right: 24px;
    background: transparent;
    box-shadow: 2px 8px 8px rgba(0, 0, 0, 0.03);
  }
  .main {
    margin-top: 50px;
    border-top: 1px solid #D5DDED;
    background-color: transparent;
    overflow-y: hidden;

    .left-menu {
      padding-top: 30px;
      height: 100%;
      background: #ffffff;
      box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.02);
    }
    .page {
    //  height: 100%;
      height: calc(100vh - 50px);
      max-height: calc(100vh - 50px);
      background-color: rgb(242, 247, 250);
      position: relative;
      overflow-y: hidden;

      .rightImage {
        display: block;
        overflow: hidden;
        width: 100%;
        max-height: 100vh;
        position: absolute; 
        top:0; 
        left: 0;
      }

      @media (max-width: 1022px) {
        .rightImage {
          display: none;
        }
        /*
          .sm\:block {
              display: block;
          }
        */
      }
    }
  }
  .mobile-main{
    height: 50%;
    padding-top: 20px;
  }
}
::v-deep .el-menu {
  border: none;
  padding: 8px 0;
  position: relative; 
  // max-width: 230px;
  // min-width: 230px;
  background-color: transparent;
  .el-image.bottomImage {
    position: fixed;
    bottom:0; 
    left:0; 
    width:229px;
    z-index: -1;
  }
}
::v-deep .el-submenu .el-menu-item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-left: 3px solid transparent;
}
.menuIcon {
  display: inline-block;
}
.el-menu-item .svg-icon {
  display: inline-block;
  width: 22px;
  height: 18px;
  margin-right: 6px;
  fill: #82889c;
  // stroke: #82889c;
}

.is-active > .svg-icon {
  // stroke: $primaryColor;
  fill: $primaryColor;
}
.el-menu {
  .is-active {
    border-left: 3px solid $primaryColor;
    font-weight: 500;
  }

  .el-submenu {
    border: none;
  }
}
::v-deep .el-submenu .el-menu-item {
  // padding: 0 0 0 40px !important;
  color: #49454F;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 30px;
  height: 34px;
  padding-right: 0px;
}
::v-deep .el-submenu {
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .el-menu-item {
  color: #49454F;
  font-size: 13px;
  // padding: 0 27px !important;
  border-left: 3px solid transparent;
  height: 34px;
  line-height: 30px;
  margin-top: 6px;
}
::v-deep .el-submenu__title {
  color: #49454F;
  font-size: 13px;
  padding: 0 24px !important;
  height: 34px;
  line-height: 30px;
  .svg-icon {
    display: inline;
    display: inline-block;
    width: 22px;
    height: 18px;
    margin-right: 6px;
    fill: #49454F;
  }
}
.menuIcon {
  width: 20px;
  height: 13px;
}
.headMenu {
  fill: #49454F;
}
.headMenu:hover {
  fill: $primaryColor;
}
::v-deep .el-menu-item {
  .svg-icon {
    transition: transform 500ms linear;
    transform: translateX(0px);
  }
}
::v-deep .el-form-item--small .el-form-item__label {
  line-height: 28px;
  padding-bottom: 5px;
}
::v-deep .el-submenu__icon-arrow {
  margin-top: -5px;
  right: 20px;
}
.circle {
  width: 9px;
  height: 9px;
  border-radius: 100%;
  border-color: gray;
  border-width: 3px;
}
::v-deep .el-popover__reference{
  border:none;
  padding-left: 16px;
  padding-right: 16px;
}
.goToTop {
  position:fixed; 
  right: 10px; 
  bottom:10px;

  button {
    padding:12px;
    width:50px; 
    height:50px;
    border-radius:25px;
  }
}
.isMobileOrTabletHamburgerMenu{
  padding-left: -1.5rem;
}
.rules {
  color: #888888; 
  font-size: 10px;
}
.changePassword {
  ::v-deep .el-form-item--small .el-form-item__label{
    padding-bottom: 0px;
  }
}
.menu-scroll {
  height: calc(100vh - 45px);
  overflow: auto;
}
.app-version {
  color: #606266;
  font-size: 14px;
  margin-right: 10px;
}
.mobile-menu{
  height: 500px;
  overflow: auto;
}
</style>
